import "./StartButton.css"
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { isGameInProgress } from "./utils";
import { ThemeContext } from "./App.js";
import { useContext } from "react";

let currentlyPlayingLevel = 0;

const StartButton = ({gameInfo, startGame, inMaintenance, newGameStartFailure,
                      selectedLevel, setSelectedLevel,
                      practiceMode, togglePracticeMode, disabled}) => {

  const beginLevel = (evt) => {
    evt.currentTarget.blur();
    currentlyPlayingLevel = selectedLevel;
    startGame(selectedLevel);
  }

  const levelSelected = (evt, newSelectedLevel) => {
    evt.currentTarget.blur();
    setSelectedLevel(newSelectedLevel);
  }

  const theme = useContext(ThemeContext);
  let buttonText;
  if (inMaintenance) {
    buttonText = (
      <>
        In Maintenance - Back Soon!
      </>
    );
  } else if (newGameStartFailure) {
    buttonText = (
      <>
        {newGameStartFailure}
      </>
    );
  } else {
    buttonText = (
      <>
        {(isGameInProgress(gameInfo) && currentlyPlayingLevel === selectedLevel
                                                ? "Restart" : "Play")+` Level ${selectedLevel}`}
      </>
    );
  }
  return(
      <>
        <Dropdown className="StartButton" as={ButtonGroup}>
          <Button className="start-button" onClick={(evt) => beginLevel(evt)} tabIndex="1"
                  variant="primary" disabled={disabled}>{buttonText}</Button>
          <Dropdown.Toggle className="dropdown-toggle" split variant="primary"  tabIndex="2"
                                               id="dropdown-split-basic"  disabled={disabled}/>
          <Dropdown.Menu>
            <Dropdown.Item onClick={(evt) => levelSelected(evt, 1)}>Level 1</Dropdown.Item>
            <Dropdown.Item onClick={(evt) => levelSelected(evt, 2)}>Level 2</Dropdown.Item>
            <Dropdown.Item onClick={(evt) => levelSelected(evt, 3)}>Level 3</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className={"practice-mode "+theme}>
          <label>
            <input type="checkbox" checked={practiceMode} className="practice-mode-checkbox"
                   disabled={disabled} onChange={togglePracticeMode} />
                Practice Mode
          </label>
        </div>
      </>
  );
}

export default StartButton;

