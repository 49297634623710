import "./InMaintenance.css"
import useOnclickOutside from "react-cool-onclickoutside";
import CloseButton from 'react-bootstrap/CloseButton'

const InMaintenance = ({stopShowingInMaintenance}) => {

  const ref = useOnclickOutside(() => stopShowingInMaintenance());

  return (
    <div className="InMaintenance" ref={ref}>
      <h1 className="in-maintenance-title">
        In Maintenance
      </h1>
      <CloseButton className="in-maintenance-close-button" onClick={() => stopShowingInMaintenance()} />
      <section className="in-maintenance-message">
        <div>The Viminator is currently down for maintenance.</div>
        <div>It should be back up within a few minutes.</div>
      </section>
    </div>
  );
}

export default InMaintenance;
