import "./KeystrokeCountDownDisplay.css";
import { isGameInProgress } from "./utils";

const KeystrokeCountDownDisplay = ({keystrokeCountDown, gameInfo, practiceMode}) => {
  let countDown;
  if (isGameInProgress(gameInfo) ? gameInfo.practiceGame : practiceMode) {
    countDown = " ∞";
  } else {
    countDown = keystrokeCountDown;
  }
  return (
          <div className={`KeystrokeCountDownDisplay
                           ${keystrokeCountDown <= 10 && isGameInProgress(gameInfo) ?
                                                                        "almost-out-of" : ""}`}>
            Keystrokes&nbsp;Left:&nbsp;{countDown}
          </div>
         );
}

export default KeystrokeCountDownDisplay;
