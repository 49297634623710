import "./Credits.css"
import useOnclickOutside from "react-cool-onclickoutside";
import CloseButton from 'react-bootstrap/CloseButton'

const Credits = ({stopShowingCredits}) => {

  const ref = useOnclickOutside(() => stopShowingCredits());

  return (
    <div className="Credits" ref={ref}>
      <h1 className="credits-title">
        Credits
      </h1>
      <CloseButton className="credits-close-button" onClick={() => stopShowingCredits()} />
      <section className="credit">
        Thank you to Bram Moolenaar and the entire Vim development team for bringing us Vim.&nbsp;
        Support Bram’s favorite charity:&nbsp;
        <i>
          <a href="http://iccf-holland.org/">
            Click here 
          </a>
          &nbsp;to help poor children in Uganda!
        </i>
      </section>
      <section className="credit">
        Thank you to everyone on the Neovim team for exposing their powerful API.&nbsp;
        We use Neovim “under the hood” for this website.
      </section>
      <section className="credit">
        And thanks to Ensar Sarajčić, the creator and maintainer of neovim-java,
        which connects our Java code to Neovim.
      </section>
    </div>
  );
}

export default Credits;
