import "./CountdownTimer.css";
import { isGameInProgress } from "./utils";

const CountdownTimer = ({timeLeft, gameInfo}) => {
  return (
          <div className={`CountdownTimer ${timeLeft <= 10 && isGameInProgress(gameInfo) ?
                                                                    "almost-out-of" : ""}`}>
            Time&nbsp;Left:&nbsp;{timeLeft}
          </div>
         );
}

export default CountdownTimer;
