import "./ViminationCounter.css";

const ViminationCounter = ({viminationCount}) => {
  return (
          <div className="ViminationCounter">
            Viminations:&nbsp;{viminationCount}
          </div>
         );
}

export default ViminationCounter;
