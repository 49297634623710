import "./EnterHighScoreName.css"
import { getServerName } from "./utils";
import { ThemeContext } from "./App.js";
import CloseButton from "react-bootstrap/CloseButton"
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useState, useContext, memo } from "react";

const EnterHighScoreName = memo(({stopEnteringHighScoreName, setHighScores, finalScore}) => {

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const handleSubmit = event => {
    event.preventDefault();
    const name = document.getElementsByClassName("high-scorers-name")[0].value;
    axios.get(getServerName()+"/high-score-name?name="+encodeURIComponent(name), {withCredentials: true})
         .then(response => {
            setHighScores(response.data);
            stopEnteringHighScoreName();
          })
         .catch(error => {
             alert("Error saving new high score: "+error);
          });
  }

  const handleChange = event => {
    const currentNameLength = event.target.value.length;
    if (!currentNameLength) {
      setSubmitDisabled(true);
    } else  if (currentNameLength === 1) {
      setSubmitDisabled(false);
    }
  }

  return (
    <div className={"EnterHighScoreName "+useContext(ThemeContext)}>
      <h1 className="score">
        You scored {finalScore} points.
      </h1>
      <h1 className="enter-high-score-title">
        Congratulations!
      </h1>
      <h4 className="enter-high-score-sub-title">
        You’ve set a high score!
      </h4>
      <div className="enter-high-score-instructions">
        Enter your name or nickname to be<br />listed on the high score board:
      </div>
      <form onSubmit={handleSubmit}>
        <input className="high-scorers-name" autoFocus onChange={handleChange}
               maxLength="20" placeholder="Enter up to 20 characters" />
        <br />
        <Button className="submit-button" type="submit" disabled={submitDisabled}>Save</Button>
      </form>
      <CloseButton className="enter-high-score-close-button"
                                                    onClick={() => stopEnteringHighScoreName()} />
    </div>
  );
});

export default EnterHighScoreName;
