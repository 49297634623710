import "./NewGameStartFailure.css"
import useOnclickOutside from "react-cool-onclickoutside";
import CloseButton from 'react-bootstrap/CloseButton'

const NewGameStartFailure = ({newGameStartFailure, stopShowingNewGameStartFailure}) => {

  const ref = useOnclickOutside(() => stopShowingNewGameStartFailure());

  return (
    <div className="NewGameStartFailure" ref={ref}>
      <h1 className="new-game-start-failure-message-title">
        Unable To Start A New Game
      </h1>
      <CloseButton className="new-game-start-failure-message-close-button"
                                                onClick={() => stopShowingNewGameStartFailure()} />
      <section className="new-game-start-failure-message">
        <div>{newGameStartFailure}</div>
      </section>
    </div>
  );
}

export default NewGameStartFailure;
