import "./Footer.css";
import { useContext, memo } from "react";
import { ThemeContext } from "./App.js";

const Footer = ({isOverlayActive}) => {
  const theme = useContext(ThemeContext);
  return (
    <div className={isOverlayActive ? "overlay-active" : ""}>
      <div className="footer-spacer-padding"></div>
      <footer className={"Footer "+theme}>
        Copyright &copy; 2023 Assuratel&nbsp;LLC.
      </footer>
    </div>
  );
}

export default Footer;
