
export const isLevelComplete = gameInfo => {
  return "levelCompleteBonus" in gameInfo.state;
}

export const isOutOfKeystrokes = gameInfo => {
  return "outOfKeystrokes" in gameInfo.state;
}

export const isOutOfTime = gameInfo => {
  return gameInfo.timeLeft === 0;
}

export const isGameInProgress = gameInfo => {
  return gameInfo != null
         && !(isLevelComplete(gameInfo) || isOutOfKeystrokes(gameInfo) || isOutOfTime(gameInfo));
}

export const isCommandLineInUse = gameInfo => {
  return gameInfo.state.searchString || gameInfo.state.pendingCommandString;
}

export const getPersonalHighScoreLevelEntryName = level => {
  return "personalHighScoreLevel"+level;
}

export const getBestPracticeTimeLevelEntryName = level => {
  return "bestPracticeTimeLevel"+level;
}

export const getServerName = () => {
  if (isLabEnvironment()) {
    return "http://localhost:8080";
  } else {
    return "https://www.theviminator.com";
  }
}

export const isLabEnvironment = () => {
  return process.env.NODE_ENV === "development";
}
