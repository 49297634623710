import "./Banner.css"

import VimLogo from "./VimLogo";
import { useContext, memo } from "react";
import { ThemeContext } from "./App.js";

const Banner = memo(() => {
  return (
    <div className="Banner">
      <div className="logo">
        <VimLogo theme={useContext(ThemeContext)} />
      </div>
      <h6 className="build-it-up">
        Hone your Vim skills!!!<br />
        Put your muscle memory to good use!!!<br />
        Impress your friends and family!!!
      </h6>
    </div>
  );
});

export default Banner;

