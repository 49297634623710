import "./ScoreBoard.css";
import KeystrokeCountDownDisplay from "./KeystrokeCountDownDisplay";
import ViminationCounter from "./ViminationCounter";
import CountdownTimer from "./CountdownTimer";

const ScoreBoard = ({gameInfo, practiceMode}) => {
  return (
          <div className="ScoreBoard">
            <div className="scoreboard-items">
              <KeystrokeCountDownDisplay gameInfo={gameInfo} practiceMode={practiceMode}
                              keystrokeCountDown={gameInfo ? gameInfo.keystrokeCountDown : 0} />
              <ViminationCounter viminationCount={gameInfo ? gameInfo.viminationCount : 0} />
              <CountdownTimer gameInfo={gameInfo} timeLeft={gameInfo ? gameInfo.timeLeft : 0} />
            </div>
          </div>
         );
}

export default ScoreBoard;
