import "./Row.css"
import { ThemeContext } from "./App.js";
import { useContext, useRef } from "react";
import { isGameInProgress } from "./utils";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger' // ?? https://react-bootstrap.github.io/components/overlays/ ??

// Currently not using memo() VVVV so tooltip disappears immediately if, e.g., Credits overlay is on
const Row = ({rowText, rowIndex, gameInfo, useRelativeLineNumbers, saidGo}) => {
  let rangeIndex = 0;
  let classForCharacter = [];
  const theme = useContext(ThemeContext);
  const getMoveHereTooltip = (suggestions) => {
    return <Tooltip className={"tooltip "+theme}>
                    Use Vim motion commands to move the cursor to the highlighted characters.<br />
                    Try using one of the following: {suggestions}</Tooltip>;
  };
  const getInTargetTooltip = (suggestions) => {
    return <Tooltip className={"tooltip "+theme}>
                    You're in range!<br />Now delete the highlighted characters.&nbsp;
                    {suggestions}</Tooltip>;
  };
  const THE_CURSOR_INSIDE_TARGET = "the-cursor-inside-target";
  const RANGE_HAS_CURSOR = " range-has-cursor";
  const cursorRow = gameInfo.state.cursorCoords[0];
  let tooltipSuggestions = "";
  if (saidGo) {
    const pushRange = (range, isCursorInRange) => {
      const {startCol, endCol, type} = range;
      classForCharacter.push({startCol, endCol, className: type.toLowerCase(), isCursorInRange});
    }
    const characterRanges = gameInfo.state.characterRanges;
    const searchRanges = characterRanges.filter(({row, type}) =>
                                                      row === rowIndex && type === "SEARCH");
    if (searchRanges.length > 0) {
      const ONE_SEARCH_PER_ROW = 0; // For now, only 1 search range per line
      pushRange(searchRanges.at(ONE_SEARCH_PER_ROW), false);
    }
    const searchActive = characterRanges.some(({type}) => type === "SEARCH");
    const targetRanges = characterRanges.filter(({row, type}) =>
                                                      row === rowIndex && type === "TARGET");
    let cursorPushed = false;
    const cursorCol = gameInfo.state.cursorCoords[1];
    let cursorIsInRange = false;
    if (targetRanges.length > 0) {
      const ONE_TARGET_PER_ROW = 0; // For now, only 1 selection range per line
      const targetRange = targetRanges.at(ONE_TARGET_PER_ROW);
      if (targetRange.tooltipSuggestions) {
        tooltipSuggestions = targetRange.tooltipSuggestions;
        // Un-resolved mystery. Originally, we tried adding the "tooltipSuggestions" property only
        // to the affected range and deleting it below. For some reason, that stopped the tooltip
        // from showing. Even though it shouldn't affect tooltips, enabling the following line
        // causes the tip to not show.
        //delete targetRange.tooltipSuggestions;
        // Enabling VVVV after enabling ^^^^ causes things to work again.
        //targetRange.tooltipSuggestions = tooltipSuggestions;
      }
      if (cursorRow === rowIndex
          && cursorCol >= targetRange.startCol
          && cursorCol < targetRange.endCol
          && !searchActive) {
        classForCharacter.push({startCol: cursorCol, endCol: cursorCol+1,
                                className: THE_CURSOR_INSIDE_TARGET});
        cursorIsInRange = true;
        cursorPushed = true;
      }
      pushRange(targetRange, cursorIsInRange);
    }
    if (!cursorPushed && cursorRow === rowIndex && !searchActive) {
      classForCharacter.push({startCol: cursorCol, endCol: cursorCol+1, className: "cursor"});
      if (!rowText) {
        rowText = " ";
      }
    }
  }
  let rowDisplay = [];
  let tooltipDisplayPending = [];
  let currentClass = "";
  let currentAdditionalClass = "";
  let additionalClass;
  let rangeJumpOut = null;
  let currentRangeJumpOut;
  let currentPartText = "";
  let cursorIsInsideTarget = false;
  const pushCurrentPart = () => {
    if (currentClass === THE_CURSOR_INSIDE_TARGET) {
      cursorIsInsideTarget = true;
    }
    const usingTooltip = currentClass.includes("target") && tooltipSuggestions;
    const display = <span className={currentClass+" "+theme+currentAdditionalClass}
                          key={rangeIndex++}>
                      {currentPartText}
                    </span>;
    if (usingTooltip) {
      tooltipDisplayPending.push(display);
    } else {
      flushTooltipDisplayPending();
      rowDisplay.push(display);
    }
  }
  const flushTooltipDisplayPending = () => {
    if (tooltipDisplayPending.length) {
      rowDisplay.push(
                  <OverlayTrigger
                    trigger="auto"
                    show={true}
                    key={rangeIndex++}
                    placement={gameInfo.state.cursorCoords[0] <= rowIndex ? "bottom" : "top"}
                    overlay={cursorIsInsideTarget ? getInTargetTooltip(tooltipSuggestions)
                                                  : getMoveHereTooltip(tooltipSuggestions)}>
                    <span>
                      {tooltipDisplayPending}
                    </span>
                  </OverlayTrigger>);
      tooltipDisplayPending = [];
    }
  }
  [...rowText].forEach ((character, index) => {
    let characterClass = "";
    additionalClass = "";
    classForCharacter.every (entry => {
      if (index >= entry.startCol && index < entry.endCol) {
        characterClass = entry.className;
        if (entry.isCursorInRange) {
          additionalClass = RANGE_HAS_CURSOR;
        }
        if (character === " ") {
          characterClass += " space";
        }
        return false;
      }
      return true;
    });
    if (characterClass !== currentClass) {
      if (currentPartText) {
        pushCurrentPart();
        currentPartText = "";
      }
      currentClass = characterClass;
      currentAdditionalClass = additionalClass;
      currentRangeJumpOut = rangeJumpOut;
      rangeJumpOut = false;
    }
    currentPartText += character;
  });
  if (currentPartText) {
    pushCurrentPart();
    flushTooltipDisplayPending();
  }

  const lineNumber = <span className={"line-number "+useContext(ThemeContext)}
                           key={rangeIndex++}>
                       {useRelativeLineNumbers ? Math.abs(rowIndex-cursorRow) : rowIndex+1}
                     </span>

  return (
           <div className="Row">
             {lineNumber}
             {rowDisplay}
           </div>
         );
};

export default Row;
