import "./App.css";
import useState from "react-usestateref"; // https://www.npmjs.com/package/react-usestateref
import { useEffect, useRef, useCallback, createContext } from "react";
import { useTimer } from "react-use-precision-timer"; // https://github.com/justinmahar/react-use-precision-timer
import Header from "./Header";
import Banner from "./Banner";
import Board from "./Board";
import StartButton from "./StartButton";
import ScoreBoard from "./ScoreBoard";
import GetFeedback from "./GetFeedback";
import Settings from "./Settings";
import Credits from "./Credits";
import InMaintenance from "./InMaintenance";
import NewGameStartFailure from "./NewGameStartFailure";
import HighScores from "./HighScores";
import EnterHighScoreName from "./EnterHighScoreName";
import Footer from "./Footer";
import axios from "axios";
import Stomp from "webstomp-client";  // https://github.com/JSteunou/webstomp-client
import SockJS from "sockjs-client";
import { isLevelComplete, isOutOfKeystrokes, getServerName, getBestPracticeTimeLevelEntryName,
         getPersonalHighScoreLevelEntryName, isGameInProgress, isLabEnvironment } from "./utils";

export const ThemeContext = createContext();

const App = () => {
  const [gameInfo, setGameInfo, gameInfoRef] = useState(null);
  const [selectedLevel, setSelectedLevel, selectedLevelRef] =
                                          useState(localStorage.getItem("currentLevel") || 1);
  // eslint-disable-next-line
  const [currentLevelPersonalHighScore, setCurrentLevelPersonalHighScore,
         currentLevelPersonalHighScoreRef] =
                  useState(localStorage.getItem(getPersonalHighScoreLevelEntryName(selectedLevel)));
  const currentLevelBestPracticeTime =
                    useRef(localStorage.getItem(getBestPracticeTimeLevelEntryName(selectedLevel)));
  const [gettingFeedback, setGettingFeedback] = useState(false);
  const [showingSettings, setShowingSettings] = useState(false);
  const [showingCredits, setShowingCredits] = useState(false);
  const [showingInMaintenance, setShowingInMaintenance] = useState(false);
  const [inMaintenance, setInMaintenance] = useState(false);
  const [showingNewGameStartFailure, setShowingNewGameStartFailure] = useState(false);
  const [newGameStartFailure, setNewGameStartFailure] = useState(null);
  const [enteringHighScoreName, setEnteringHighScoreName] = useState(false);
  const [useRelativeLineNumbers, setUseRelativeLineNumbers] =
                          useState(localStorage.getItem("useRelativeLineNumbers") === "true");
  const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") !== "false");
  const [practiceMode, setPracticeMode] = useState(localStorage.getItem("practiceMode") === "true");
  const [highScores, setHighScores] = useState(null);
  const gameStartingUp = useRef(false);
  const debugDisplayKeysPressedRef = useRef("");
  //const debugScriptedKeysPending = useRef(false);
  const waitingForServerKeyResponseRef = useRef(false);
  const keyQueueRef = useRef([]);
  const stompClientRef = useRef(null);
  const [startGameCountdown, setStartGameCountdown] = useState();
  const [saidGo, setSaidGo] = useState(false);
  const lastKeystrokeTimeLeft = useRef(0);
  const startGameCountdownTimer = useTimer({delay: (isLabEnvironment() ? 100 : 1000),
                                    callback: () => setStartGameCountdown(startGameCountdown-1) });
  const sayGoTimer = useTimer({delay: (isLabEnvironment ? 75 : 750),
                               callback: () => {setSaidGo(true);
                                                resetSuggestionTimer();}});
  const timeLeftTimer = useTimer({delay: 1000, callback: () => {
    //if (isLabEnvironment() && practiceMode) {
    //  return;
    //}
    const newTimeLeft = gameInfoRef.current.timeLeft - 1;
    const updatedGameInfo = {...gameInfoRef.current};
    updatedGameInfo.timeLeft = newTimeLeft;
    setGameInfo(updatedGameInfo);
    const timeSinceLastKeystroke = lastKeystrokeTimeLeft.current-newTimeLeft;
  }});
  
  const startGame = (level) => {
    if (isLabEnvironment()) {
      console.log(`--------------------------- Start Game ---------------------------`);
    } else {
      console.log();
      console.log(`*******************************************************************`);
      console.log(`* Never trust the browser! Everything is validated by the server. *`);
      console.log(`*******************************************************************`);
    }
    document.removeEventListener("keydown", handleKeyDown);
    let url = getServerName()+"/new-game?level="+selectedLevel;
    if (practiceMode) {
      url += "&practice=true";
    }
    axios.get(url, {withCredentials: true})
         .then((response) => {
           const newGameInfo = response.data;
           if ("inMaintenance" in newGameInfo) {
             setInMaintenance(true);
             return;
           }
           if ("newGameStartFailure" in newGameInfo) {
             setNewGameStartFailure(newGameInfo.newGameStartFailure);
             return;
           }
           newGameInfo.keystrokeCountDown = newGameInfo.board.keystrokesAllowed;
           newGameInfo.timeLeft = lastKeystrokeTimeLeft.current = newGameInfo.board.secondsAllowed;
           newGameInfo.viminationCount = 0;
           newGameInfo.practiceGame = practiceMode;
           setHighScores(newGameInfo.highScores);
           setGameInfo(newGameInfo);
           connectStomp();
           debugDisplayKeysPressedRef.current = "";
           timeLeftTimer.stop();
           gameStartingUp.current = true;
           setSaidGo(false);
           setStartGameCountdown(3);
           startGameCountdownTimer.start();
         })
         .catch((error) => {
           alert("Start Game error: "+error);
         });
  }

  const levelDone = () => {
    disconnectStomp();
    document.removeEventListener("keydown", handleKeyDown);
  }

  const connectStomp = () => {
    waitingForServerKeyResponseRef.current = false;
    const socket = new SockJS(getServerName()+"/ws");
    stompClientRef.current = Stomp.over(socket, {debug: false});
    stompClientRef.current.connect({},
      frame => {
        stompClientRef.current.subscribe("/user/topic/keystroke-response", state => {
          processKeystrokeResponse(JSON.parse(state.body));
        });
      },
      error => {
        alert("Websocket connection error");
      }
    );
  };

  const disconnectStomp = () => {
    stompClientRef.current.disconnect();
    stompClientRef.current = null;
  }

  const timesUpGetScore = () => {
    axios.get(getServerName()+"/times-up-get-score", {withCredentials: true})
          .then(response => {
            let updatedGameInfo = {...gameInfoRef.current};
            updatedGameInfo.state.finalScore = response.data.finalScore;
            updatedGameInfo.keystrokeCountDown = response.data.finalKeystrokeCountDown;
            updatedGameInfo.viminationCount = response.data.finalViminationCount;
            updatedGameInfo.state.isHighScore = response.data.isHighScore;
            setHighScores(response.data.highScores);
            checkHighScore(updatedGameInfo.state);
            setGameInfo(updatedGameInfo);
           })
          .catch(error => {
             alert("Error getting score: "+error);
          });
  }
 
  const handleKeyDown = useCallback(event => {
    if (gameInfoRef.current === null || !stompClientRef.current
                                     || !stompClientRef.current.connected) {
      return;
    }
    const specialKeys = ["Enter", "Escape", "Backspace", "Delete", "Home", "End",
                         "ArrowUp", "ArrowRight", "ArrowDown", "ArrowLeft"];
    let keyPressed = event.key;
    if (keyPressed.length > 1 && !specialKeys.includes(keyPressed)) {
      return;
    }
    lastKeystrokeTimeLeft.current = gameInfoRef.current.timeLeft;
    resetSuggestionTimer();
    event.stopPropagation();
    event.preventDefault();
    if (event.ctrlKey) {
      keyPressed = "CTRL-"+keyPressed;
    }
    const currentGameInfo = {...gameInfoRef.current};
    const keyInfo = {"key": keyPressed, "time": currentGameInfo.timeLeft};
    keyStrokeLoggerr(keyPressed);
    if (isLabEnvironment()) {
      //if (debugScriptedKeysPending.current === true) {
      //  [..."eeeebd$d0j4ddjlllllllllldtlll...ldfqDdiwd0jjw3dw..dddf.jj.dkDkkD"].forEach(ch => {
      //    const debugKeyInfo = {"key": ch, "time" : currentGameInfo.timeLeft};
      //    keyQueueRef.current.push(debugKeyInfo);
      //    debugScriptedKeysPending.current = true;
      //  });
      //}
    }
    if (waitingForServerKeyResponseRef.current) {
      keyQueueRef.current.push(keyInfo);
      return;
    }
    waitingForServerKeyResponseRef.current = true;
    sendKeyStroke(keyInfo);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const processKeystrokeResponse = state => {
    let updatedGameInfo = {...gameInfoRef.current};
    updatedGameInfo.state = state;
    if ("levelDoneHighScores" in updatedGameInfo.state) {
      setHighScores(updatedGameInfo.state.levelDoneHighScores);
    }
    if (!updatedGameInfo.practiceGame) {
      updatedGameInfo.keystrokeCountDown--;
    }
    if ("serverDeterminedOutOfTime" in updatedGameInfo.state) {
      updatedGameInfo.timeLeft = 0;
      finalKeystroke(updatedGameInfo);
    } else {
      if (isLevelComplete(updatedGameInfo)) {
        finalKeystroke(updatedGameInfo);
      } else {
        if ("updatedRow" in updatedGameInfo.state) {
          const updatedRowNum = updatedGameInfo.state.cursorCoords[0];
          updatedGameInfo.viminationCount +=
                    updatedGameInfo.board.rows[updatedRowNum].replace(/ /g,"").length -
                    updatedGameInfo.state.updatedRow.replace(/ /g,"").length;
          updatedGameInfo.board.rows[updatedRowNum] = updatedGameInfo.state.updatedRow;
          updatedGameInfo.state.updatedRow = null;
        }
        if ("firstRemovedRowIndex" in updatedGameInfo.state) {
          let rowNum = updatedGameInfo.state.firstRemovedRowIndex;
          for (let i = updatedGameInfo.state.rowsRemovedCount; i > 0; i--) {
            updatedGameInfo.viminationCount +=
                                      updatedGameInfo.board.rows[rowNum].replace(/ /g,"").length;
            rowNum++;
          }
          updatedGameInfo.board.rows.splice(updatedGameInfo.state.firstRemovedRowIndex,
                                            updatedGameInfo.state.rowsRemovedCount);
          updatedGameInfo.state.firstRemovedRowIndex = null;
          updatedGameInfo.state.rowsRemovedCount = null;
        }
        if (isOutOfKeystrokes(updatedGameInfo)) {
          finalKeystroke(updatedGameInfo);
        }
      }
    }
    setGameInfo(updatedGameInfo);
    if (keyQueueRef.current.length > 0) {
      const keyStroke = keyQueueRef.current.shift();
      keyStrokeLoggerr(keyStroke["key"]);
      sendKeyStroke(keyStroke);
    } else {
      waitingForServerKeyResponseRef.current = false;
    }
  };

  const keyStrokeLoggerr = keyPressed => {
    if (isLabEnvironment()) {
      const currentDebugDisplayKeysPressed = debugDisplayKeysPressedRef.current+" "+keyPressed;
      debugDisplayKeysPressedRef.current = currentDebugDisplayKeysPressed.slice(-80);
    }
  }

  const finalKeystroke = updatedGameInfo => {
    checkHighScore(updatedGameInfo.state);
    updatedGameInfo.keystrokeCountDown = updatedGameInfo.state.finalKeystrokeCountDown;
    updatedGameInfo.viminationCount = updatedGameInfo.state.finalViminationCount;
    updatedGameInfo.timeLeft = updatedGameInfo.state.finalClientTimeLeft;
  }

  const sendKeyStroke = keyInfo => {
    if (stompClientRef.current !== null) {
      stompClientRef.current.send("/app/keystroke", JSON.stringify(keyInfo));
    } else {
      keyQueueRef.current = [];
      waitingForServerKeyResponseRef.current = false;
    }
  }

  const checkHighScore = gameState => {
    if (gameInfoRef.current.practiceGame) {
      const bestPracticeTimeLevelEntryName =
                                    getBestPracticeTimeLevelEntryName(selectedLevelRef.current);
      const currentBestPracticeTime = localStorage.getItem(bestPracticeTimeLevelEntryName);
      if (gameState.finalClientTimeLeft
          && (!currentBestPracticeTime
              || currentBestPracticeTime < gameState.finalClientTimeLeft)) {
        localStorage.setItem(bestPracticeTimeLevelEntryName, gameState.finalClientTimeLeft);
        currentLevelBestPracticeTime.current = gameState.finalClientTimeLeft;
      }
    } else {
      const personalHighScoreLevelEntryName =
                                    getPersonalHighScoreLevelEntryName(selectedLevelRef.current);
      const currentPersonalHighScore = localStorage.getItem(personalHighScoreLevelEntryName);
      if (gameState.finalScore !== undefined
          && (!currentPersonalHighScore || currentPersonalHighScore < gameState.finalScore)) {
        localStorage.setItem(personalHighScoreLevelEntryName, gameState.finalScore);
        setCurrentLevelPersonalHighScore(gameState.finalScore);
      }
      if (gameState.isHighScore) {
        // Give time for them to read the results and see the fireworks
        setTimeout(() => {
          setEnteringHighScoreName(true);
        }, 1500);
      }
    }
  }

  const getFeedback = () => {
    document.removeEventListener("keydown", handleKeyDown);
    setGettingFeedback(true);
  }

  const stopGettingFeedback = () => {
    if (isGameInProgress(gameInfo)) {
      document.addEventListener("keydown", handleKeyDown);
    }
    setGettingFeedback(false);
  }

  const showSettings = () => {
    setShowingSettings(true);
  }

  const stopShowingSettings = () => {
    resetSuggestionTimer();
    setShowingSettings(false);
  }

  const showInMaintenance = () => {
    setShowingInMaintenance(true);
  }

  const stopShowingInMaintenance = () => {
    setShowingInMaintenance(false);
  }

  const showNewGameStartFailure = () => {
    setShowingNewGameStartFailure(true);
  }

  const stopShowingNewGameStartFailure = () => {
    setShowingNewGameStartFailure(false);
  }

  const showCredits = () => {
    setShowingCredits(true);
  }

  const stopShowingCredits = () => {
    setShowingCredits(false);
  }

  const toggleUseRelativeLineNumbers = () => {
    let newValue;
    setUseRelativeLineNumbers(current => newValue = !current);
    localStorage.setItem("useRelativeLineNumbers", newValue);
  }

  const toggleDarkMode = () => {
    let newValue;
    setDarkMode(current => newValue = !current);
    localStorage.setItem("darkMode", newValue);
  }

  const togglePracticeMode = () => {
    let newValue;
    setPracticeMode(current => newValue = !current);
    localStorage.setItem("practiceMode", newValue);
  }

  const stopEnteringHighScoreName = () => {
    setEnteringHighScoreName(false);
  }

  const newLevelSelected = level => {
    setSelectedLevel(level);
    currentLevelPersonalHighScoreRef.current =
                                localStorage.getItem(getPersonalHighScoreLevelEntryName(level));
    currentLevelBestPracticeTime.current =
                                localStorage.getItem(getBestPracticeTimeLevelEntryName(level));
    localStorage.setItem("currentLevel", level);
  }

  useEffect(() => {
    if (!isGameInProgress(gameInfo) && timeLeftTimer.isStarted()) {
      timeLeftTimer.stop();
      if (gameInfo.timeLeft === 0) {
        timesUpGetScore();
      }
      levelDone();
    }
  });

  useEffect(() => {
    if (startGameCountdown === 0) {
      startGameCountdownTimer.stop();
      sayGoTimer.start();
    }
  }, [startGameCountdown]);

  useEffect(() => {
    if (saidGo) {
      sayGoTimer.stop();
      if (!gettingFeedback) {
        document.addEventListener("keydown", handleKeyDown);
      }
      timeLeftTimer.start();
      gameStartingUp.current = false;
    }
  }, [saidGo]);

  useEffect(() => {
    if (inMaintenance) {
      showInMaintenance();
    }
  }, [inMaintenance]);

  useEffect(() => {
    if (newGameStartFailure) {
      showNewGameStartFailure();
    }
  }, [newGameStartFailure]);

  const [suggestionDelay, setSuggestionDelay, suggestionDelayRef] =
                                            useState(localStorage.getItem("suggestionDelay") || 3);
  const saveSetSuggestionDelay = (delay) => {
    if (delay <= 0) {
      delay = 0.1;
    } else if (delay > 10) {
      if (delay == 999) {
        delay = 10;
      } else {
        delay = 1000;
      }
    }
    localStorage.setItem("suggestionDelay", delay);
    setSuggestionDelay(delay);
  }
  useEffect(() => {
    resetSuggestionTimer();
  }, [suggestionDelay]);
  const [makeSuggestion, setMakeSuggestion] = useState(false);
  const [suggestionTimer, setSuggestionTime, suggestionTimerRef] = useState(null);
  const resetSuggestionTimer = () => {
    setMakeSuggestion(false);
    if (suggestionTimerRef.current) {
      clearTimeout(suggestionTimerRef.current);
    }
    suggestionTimerRef.current =
                        setTimeout(() => setMakeSuggestion(true), suggestionDelayRef.current*1000);
  }

  if (darkMode) {
    document.body.classList.add("dark-mode-body");
  } else {
    document.body.classList.remove("dark-mode-body");
  }
  const isOverlayActive = gettingFeedback || showingSettings || showingCredits
                          || showingNewGameStartFailure || showingInMaintenance
                          || enteringHighScoreName;
  if (isOverlayActive || !isGameInProgress(gameInfo)) {
    if (makeSuggestion) {
      setMakeSuggestion(false);
    }
  }
  return (
    <div className="App">
      <ThemeContext.Provider value={darkMode ? "dark" : "light"}>
        <Header feedbackCallback={getFeedback} settingsCallback={showSettings}
                creditsCallback={showCredits} />
        <div className="footer-wrapper">
          <div className={isOverlayActive ? "overlay-active" : ""}>
            <Banner />
            <Board gameInfo={gameInfo} useRelativeLineNumbers={useRelativeLineNumbers}
                   startGameCountdown={startGameCountdown} saidGo={saidGo}
                   makeSuggestion={makeSuggestion} />
            <ScoreBoard gameInfo={gameInfo} practiceMode={practiceMode} />
            {<StartButton gameInfo={gameInfo} startGame={startGame} inMaintenance={inMaintenance}
                          newGameStartFailure={newGameStartFailure}
                          selectedLevel={selectedLevel} setSelectedLevel={newLevelSelected}
                          practiceMode={practiceMode} togglePracticeMode={togglePracticeMode}
                          disabled={enteringHighScoreName || inMaintenance
                                    || newGameStartFailure || gameStartingUp.current} />}
            {debugDisplayKeysPressedRef.current
                && <div className="debug-keys-pressed">{debugDisplayKeysPressedRef.current}</div>}
            <HighScores selectedLevel={selectedLevel}  highScores={highScores}
                        showPractice={isGameInProgress(gameInfo) ? gameInfo.practiceGame
                                                                 : practiceMode}
                        currentLevelPersonalHighScore={currentLevelPersonalHighScoreRef.current}
                        currentLevelBestPracticeTime={currentLevelBestPracticeTime.current}
                        setHighScores={setHighScores} setInMaintenance={setInMaintenance}/>
          </div>
        </div>
        {gettingFeedback && <GetFeedback stopGettingFeedback={stopGettingFeedback} />}
        {showingSettings && <Settings stopShowingSettings={stopShowingSettings}
                                      useRelativeLineNumbers={useRelativeLineNumbers}
                                      toggleUseRelativeLineNumbers={toggleUseRelativeLineNumbers}
                                      darkMode={darkMode}
                                      toggleDarkMode={toggleDarkMode}
                                      suggestionDelay={suggestionDelay}
                                      setSuggestionDelay={saveSetSuggestionDelay} />}
        {showingCredits && <Credits stopShowingCredits={stopShowingCredits} />}
        {showingInMaintenance &&
                            <InMaintenance stopShowingInMaintenance={stopShowingInMaintenance} />}
        {showingNewGameStartFailure && <NewGameStartFailure
                                newGameStartFailure={newGameStartFailure}
                                stopShowingNewGameStartFailure={stopShowingNewGameStartFailure} />}
        {enteringHighScoreName &&
                      <EnterHighScoreName stopEnteringHighScoreName={stopEnteringHighScoreName}
                                          setHighScores={setHighScores}
                                          finalScore={gameInfo.state.finalScore} />}
        <Footer isOverlayActive={isOverlayActive} />
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
