import Row from "./Row" ;
import CommandLine from "./CommandLine";
import FinalScore from "./FinalScore";
import "./Board.css";
import { useContext, memo } from "react";
import { ThemeContext } from "./App.js";
import { isLevelComplete, isOutOfKeystrokes, isOutOfTime,
         isGameInProgress, isCommandLineInUse } from "./utils";

import celebrate1 from "./celebrate1.gif";

const Board = memo(({gameInfo, useRelativeLineNumbers,
                     startGameCountdown, saidGo, makeSuggestion}) => {
  let theBoard = [];
  let additionalClass;
  let giveCommandLineSuggestion = false;
  const theme = useContext(ThemeContext);
  if (makeSuggestion) {
    if (isCommandLineInUse(gameInfo)) {
      giveCommandLineSuggestion = true;
    } else {
      setTooltipTarget(gameInfo);
    }
  } else if (gameInfo) {
    clearTooltipTarget(gameInfo);
  }
  if (gameInfo === null) {
    additionalClass="board-idle";
    theBoard.push(<section key={theBoard.length}>
                <div className="viminate-definition">Viminate&nbsp;&nbsp;<i>(verb)</i>&nbsp;
                                                     /vimˈəˌnāt/&nbsp;&nbsp;
                                                     to completely remove or get rid of (something)
                                                     using the power of Vim.</div>
                  <h3 className="instructions-header">Instructions</h3>
                  <ul className="instructions">
                  <li>Your goal is to Viminate all characters in the window</li>
                  <li>You can only Viminate highlighted letters and punctuation</li>
                  <li>Use only Vim motion and delete commands (no mice!)</li>
                  <li>Keystrokes are limited...use them wisely</li>
                  <li>The clock will be ticking, so time is of the essence</li>
                  <li>Keystrokes Left+Viminations+Time Left = Total Score</li>
                  <li>Bonus points awarded for completing the level</li>
                </ul>
               </section>);
  } else if (isLevelComplete(gameInfo)) {
    additionalClass="board-level-complete "+theme;
    theBoard.push(<span key={theBoard.length}>
                    <div className="board-level-complete-text">Level<br />Complete!
                      {gameInfo.practiceGame || 
                        <>
                          <div className="board-level-complete-bonus-points">
                            {gameInfo.state.levelCompleteBonus} Bonus Points
                          </div>
                          <FinalScore score={gameInfo.state.finalScore} />
                        </>
                      }
                    </div>
                    {/* Adding Date to GIF request so Chrome won't use cached copy.
                        Otherwise it won't replay the animation after the 1st victory.
                        Also, leave "alt" blank, as it's briefly displayed before animation. */}
                    <img className="board-level-complete-fireworks" alt=""
                         src={celebrate1+"?="+Date.now()} />
                  </span>);
  } else {
    if (startGameCountdown) {
      switch (startGameCountdown) {
        // Using a switch so the CSS "animation" is triggered for every change
        case 1:
          theBoard.push(<div key={99999} className="count-down">1</div>);
          break;
        case 2:
          theBoard.push(<div key={99998} className="count-down">2</div>);
          break;
        case 3:
        default:
          theBoard.push(<div key={99997} className="count-down">3</div>);
          break;
      }
    } else if (!saidGo) {
      theBoard.push(<div key={99990} className="count-down">Go!</div>);
    }
    theBoard.push(<div key={theBoard.length}>
                    <div className={`the-targets text-monospace \
                                   ${saidGo ? "" : "start-game-countdown-active"} \
                                   ${isOutOfTime(gameInfo) || isOutOfKeystrokes(gameInfo)
                                                         ? "board-level-over" : "board-in-play"}`}>
                       {/* Focused input field prevents browser extensions (e.g., Vimium)
                           from intercepting keystroke */}
                       {!saidGo || <input type="text" onBlur={(e)=>e.target.focus()}
                                          autoFocus={true} className="always-in-focus-input" />}
                       { gameInfo.board.rows.map((contents, i) =>
                                          <Row rowText={contents} rowIndex={i} gameInfo={gameInfo}
                                               useRelativeLineNumbers={useRelativeLineNumbers}
                                               saidGo={saidGo}
                                               key={i}/>) }
                    </div>
                  </div>);
  }
  if (gameInfo !== null && gameInfo.state.finalScore !== undefined) {
    if (isOutOfTime(gameInfo)) {
      theBoard.push(<section className="level-over-text" key={theBoard.length}>
                      <div>Time’s Up!</div>
                      {gameInfo.practiceGame || <FinalScore score={gameInfo.state.finalScore} />}
                    </section>);
    } else if (isOutOfKeystrokes(gameInfo)) {
      theBoard.push(<section className="level-over-text" key={theBoard.length}>
                      <div>Out Of Keystrokes!</div>
                      <FinalScore score={gameInfo.state.finalScore} />
                    </section>);
    }
  }
  return (
      <div className="Board">
        <div className="board-holder">
          <div className={"board-display "+theme+(additionalClass ? " "+additionalClass : "")}>
            {theBoard}
          </div>
          {gameInfo && <CommandLine gameIsInProgress={isGameInProgress(gameInfo)}
                                    giveSuggestion={giveCommandLineSuggestion}
                                    leftMessage={gameInfo.state.searchString}
                                    rightMessage={gameInfo.state.pendingCommandString}
                                    alertMessage={gameInfo.state.viminatedNonHighlightedCharacter ?
                                        "You can only Viminate highlighted characters!" : null} />}
        </div>
      </div>
  );
});

const setTooltipTarget = (gameInfo) => {
  const targetRanges = gameInfo.state.characterRanges.filter(({type}) => type === "TARGET");
  let closestDistance = Number.MAX_VALUE;
  const cursorRow = gameInfo.state.cursorCoords[0];
  const cursorCol = gameInfo.state.cursorCoords[1];
  let tooltipTargetIndex;
  targetRanges.forEach ((entry, index) => {
    entry.tooltipSuggestions = "";
    const colDistance = getColDistance(entry, cursorCol, gameInfo.board.rows[cursorRow]);
    const rowDistance = getRowDistance(entry, cursorRow);
    const distance = colDistance + rowDistance;
    if (distance < closestDistance) {
      closestDistance = distance;
      tooltipTargetIndex = index;
    }
  });
  setSuggestions(targetRanges[tooltipTargetIndex], cursorRow, cursorCol, gameInfo);
}
const clearTooltipTarget = (gameInfo) => {
  const targetRanges = gameInfo.state.characterRanges.filter(({type}) => type === "TARGET");
  targetRanges.forEach ((entry, index) => {
    entry.tooltipSuggestions = "";
  });
}
const getColDistance = (targetRange, cursorCol, currentRowText) => {
  let charactersBetween;
  if (targetRange.startCol > cursorCol) {
    charactersBetween = currentRowText.substring(cursorCol, targetRange.startCol);
  } else  if (targetRange.endCol < cursorCol) {
    charactersBetween = currentRowText.substring(targetRange.startCol, cursorCol);
  } else {
    return 0;
  }
  return (charactersBetween.match(/\s+/g) || []).length;
}
const getRowDistance = (targetRange, cursorRow) => {
  return Math.abs(targetRange.row - cursorRow);
}

const setSuggestions = (targetRange, cursorRow, cursorCol, gameInfo) => {
  if (targetRange.row === cursorRow) {
    if (cursorCol >= targetRange.startCol && cursorCol < targetRange.endCol) {
      return setDeleteSuggestions(targetRange, cursorCol, gameInfo);
    }
  }
  return setMovementSuggestions(targetRange, cursorRow, cursorCol, gameInfo);
}

const buildSuggestions = (...newSuggestions) => {
  let result = "";
  for (const suggestion of newSuggestions) {
    result += "   "+suggestion;
  }
  return result;
}

const setMovementSuggestions = (targetRange, cursorRow, cursorCol, gameInfo) => {
  const currentRowText = gameInfo.board.rows[targetRange.row];
  const targetHasPunctuation = /[^a-zA-Z0-9]/.test(
                            currentRowText.substring(targetRange.startCol, targetRange.endCol));
  let trimmedTargetStartCol = targetRange.startCol;
  let trimmedTargetEndCol = targetRange.endCol;
  const trimmedRowText = currentRowText.replace(/^\s+/, (match, offset, string) => {
                                                    const leadingSpacesCount = match.length;
                                                    trimmedTargetStartCol -= leadingSpacesCount;
                                                    trimmedTargetEndCol -= leadingSpacesCount;
                                                    return "";
                                                  }).replace(/\s+$/, "");
  let suggestion = "";
  if (cursorRow === targetRange.row) {
    if (!targetRange.startCol) {
      suggestion += buildSuggestions("0","^");
    }
    if (targetRange.endCol === currentRowText.length) {
      suggestion += buildSuggestions("$");
    }
    if (cursorCol < targetRange.startCol) {
      if (targetRange.endCol !== currentRowText.length
          && trimmedTargetEndCol === trimmedRowText.length) {
        suggestion += buildSuggestions("g_");
      }
      suggestion += buildSuggestions("w","f","l","/");
    } else {
      if (targetRange.startCol && !trimmedTargetStartCol) {
        suggestion += buildSuggestions("^");
      }
      suggestion += buildSuggestions(targetHasPunctuation ? "B" : "b","F","h","?");
    }
  } else {
    if (!targetRange.row && cursorRow > 1) {
      suggestion += buildSuggestions("gg");
    } else {
      const currentRowCount = gameInfo.board.rows.length;
      if (targetRange.row === currentRowCount-1 && cursorRow < targetRange.row-1) {
        suggestion += buildSuggestions("G");
      } else if (Math.abs(cursorRow - targetRange.row) > (targetRange.row >= 9 ? 3 : 2)) {
        suggestion += buildSuggestions((targetRange.row+1)+"G");
      }
    }
    if (cursorRow < targetRange.row) {
      suggestion += buildSuggestions("j");
    } else {
      suggestion += buildSuggestions("k");
    }
    if (cursorCol < targetRange.startCol) {
      suggestion += buildSuggestions("w","l");
    } else if (cursorCol >= targetRange.endCol) {
      suggestion += buildSuggestions("b","h");
    }
    if (cursorRow < targetRange.row) {
      suggestion += buildSuggestions("/");
    } else if (cursorRow >= targetRange.row) {
      suggestion += buildSuggestions("?");
    }
  }
  targetRange.tooltipSuggestions = <>
                                     <span className="suggestion">{suggestion}</span><br />
                                     <span className="suggestion-case-matters">(command’s case matters)</span>
                                   </>;
}

const setDeleteSuggestions = (targetRange, cursorCol, gameInfo) => {
  if (gameInfo.board.level > 0) {
    targetRange.tooltipSuggestions = "   Try using d{motion} or [count]x";
    return;
  }
  let targetStartCol = targetRange.startCol;
  let targetEndCol = targetRange.endCol;
  const trimmedRowText = gameInfo.board.rows[targetRange.row].replace(/^\s+/,
                                                  (match, offset, string) => {
                                                    const leadingSpacesCount = match.length;
                                                    cursorCol -= leadingSpacesCount;
                                                    targetStartCol -= leadingSpacesCount;
                                                    targetEndCol -= leadingSpacesCount;
                                                    return "";
                                                  }).replace(/\s+$/, "");
  const targetHasPunctuation = /[^a-zA-Z0-9]/.test(
                                          trimmedRowText.substring(targetStartCol, targetEndCol));
  let suggestion = "";
  if (!/[\s]/.test(trimmedRowText)) {
    suggestion += buildSuggestions("dd");
  }
  if (targetStartCol === cursorCol) {
    if (targetEndCol === trimmedRowText.length) {
      suggestion += buildSuggestions("D");
    }
    const isLastCharacterBeforeAWord = cursorCol !== trimmedRowText.length
                                       && trimmedRowText.charAt(cursorCol+1) === ' ';
    if (targetHasPunctuation) {
      suggestion += buildSuggestions("dW");
      if (!isLastCharacterBeforeAWord) {
        suggestion += buildSuggestions("dE");
      }
    } else {
      suggestion += buildSuggestions("dw");
      if (!isLastCharacterBeforeAWord) {
        suggestion += buildSuggestions("de");
      }
    }
  } else if (targetEndCol-1 === cursorCol) {
    const isFirstCharacterAfterAWord = cursorCol && trimmedRowText.charAt(cursorCol-1) === ' ';
    if (targetHasPunctuation) {
      suggestion += buildSuggestions("diW");
      if (!isFirstCharacterAfterAWord) {
        suggestion += buildSuggestions("dB");
      }
    } else {
      suggestion += buildSuggestions("diw");
      if (!isFirstCharacterAfterAWord) {
        suggestion += buildSuggestions("db");
      }
    }
    if (!targetStartCol) {
      suggestion += buildSuggestions("d0 ","^");
    }
  } else {
    if (targetHasPunctuation) {
      suggestion += buildSuggestions("diW");
    } else {
      suggestion += buildSuggestions("diw");
    }
  }
  suggestion += buildSuggestions("x");
  targetRange.tooltipSuggestions = <>
                                     <span>Try one of these:</span><br />
                                     <span className="suggestion">{suggestion}</span><br />
                                     <span className="suggestion-case-matters">(command’s case matters)</span>
                                   </>;
}

export default Board;
