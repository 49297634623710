import "./Settings.css"
import useOnclickOutside from "react-cool-onclickoutside";
import CloseButton from "react-bootstrap/CloseButton"

const Settings = ({stopShowingSettings, useRelativeLineNumbers, toggleUseRelativeLineNumbers,
                   darkMode, toggleDarkMode, suggestionDelay, setSuggestionDelay }) => {
  const ref = useOnclickOutside(() => stopShowingSettings());
  return (
    <div className="Settings" ref={ref}>
      <h1 className="settings-title">
        Settings
      </h1>
      <CloseButton className="settings-close-button" onClick={() => stopShowingSettings()} />
      <div>
      <div>
        <input type="checkbox" checked={useRelativeLineNumbers} id="useRelativeLineNumbers"
                               onChange={toggleUseRelativeLineNumbers} />
        <label htmlFor="useRelativeLineNumbers">Use Relative Line Numbers</label>
      </div>
        <input type="checkbox" checked={darkMode} id="darkMode"
                               onChange={toggleDarkMode} />
        <label htmlFor="darkMode">Dark Mode</label>
      </div>
      <div>
        <input className="suggestion-delay" type="number" id="suggestionDelay"
               value={suggestionDelay} min="0" max="999" step="1"
               onChange={e => setSuggestionDelay(e.target.value)} />
        <label htmlFor="suggestionDelay">Show Tips Delay (in seconds)</label>
      </div>
    </div>
  );
}

export default Settings;
