import "./GetFeedback.css"
import { useContext, useState } from "react";
import { ThemeContext } from "./App.js";
import Button from "react-bootstrap/Button";
import useOnclickOutside from "react-cool-onclickoutside";
import CloseButton from "react-bootstrap/CloseButton"
import axios from "axios";
import { getServerName } from "./utils";

const GetFeedback = ({stopGettingFeedback}) => {

  const MAX_FEEDBACK_LENGTH = 5000;

  const onClickOutside = useOnclickOutside(() => stopGettingFeedback());
  const [feedback, setFeedback] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);
  const theme = useContext(ThemeContext);

  const sendFeedback = (event) => {
    event.target.disabled = true;
    event.target.textContent = "Sending Feedback...";
    axios.post(getServerName()+"/feedback", {
      feedback: feedback,
      emailAddress: emailAddress
    })
    .then(function (response) {
      setFeedbackSent(true);
    })
    .catch(function (error) {
      alert("Error sending feedback: "+error);
      event.target.disabled = false;
      event.target.textContent = "Retry Sending Feedback";
    });
  }

  return (
    <div className={"GetFeedback "+useContext(ThemeContext)} ref={onClickOutside}>
      <h1 className="get-feedback-title">
        Feedback
      </h1>
      <CloseButton className="get-feedback-close-button" onClick={() => stopGettingFeedback()} />
      {!feedbackSent ?
        <>
          <textarea autoFocus className="get-feedback-feedback-field"
                 placeholder="Enter your feedback here" maxLength={MAX_FEEDBACK_LENGTH}
                 onChange={event => setFeedback(event.target.value)} />
          <div className="get-feedback-characters-left">
            Characters Left: {MAX_FEEDBACK_LENGTH - feedback.length}
          </div>
          <div className="get-feedback-email-prompt">
            Would you like a response?
          </div>
          <input type="text" className="get-feedback-email-field"
                 placeholder="Enter you email address (optional)" maxLength="320"
                 onChange={event => setEmailAddress(event.target.value)} />
          <div className="send-feedback">
            <Button disabled={!feedback.length} className="send-feedback-button"
                    onClick={(event) => sendFeedback(event)}>
              {feedback.length === 0 ? "Enter Your Feedback" : "Send Feedback"}
            </Button>
          </div>
        </>
      :
        <div className="get-feedback-feedback-sent">
          <div>Your feedback<br />has been sent.</div>
          <div className="get-feedback-thank-you">Thank you!</div>
        </div>
      }
    </div>
  );
}

export default GetFeedback;
