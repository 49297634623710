import styles from "./HighScores.module.css";
import { useEffect, useContext, memo } from "react";
import { ThemeContext } from "./App.js";
import axios from "axios";
import { getServerName } from "./utils";

//let counter = 0;

const HighScores = memo(({selectedLevel, highScores, showPractice, currentLevelPersonalHighScore,
                          currentLevelBestPracticeTime, setHighScores, setInMaintenance}) => {
// Explain: Why does "counter" get inc'd 2x when it should only inc by 1? Even stranger, the below
// "alert()" is called for every inc BUT "console.log()" only displays once for the 2 incs!
//console.log(`SLS Initially: ${counter}---------------------------------`);    
//counter++;
////alert(`SLS post-inc: ${counter}`);    
//console.log(`SLS post-inc: ${counter}`);    
  const theme = useContext(ThemeContext);

  useEffect(() => {
    axios.get(getServerName()+"/get-high-scores", {withCredentials: true})
          .then(response => {
              setHighScores(response.data.highScores);
              setInMaintenance("inMaintenance" in response.data);
           })
          .catch(error => {
             alert("Error getting high scores: "+error);
          });
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const getScoreInfo = (scoreInfo, rank) => {
    return <span className={styles.scoreInfo}>
             <span className={styles.rank}>{rank+1})</span>
             <span className={styles.score}>{scoreInfo ? scoreInfo.score : "0"}</span>
             <span className={styles.playerName}>{scoreInfo ? scoreInfo.playerName : "Name"}</span>
           </span>
  }

  const highScoreRows = [];
  if (highScores) {
    const levelHighScores = highScores[selectedLevel-1];
    for (let i=0; i !== levelHighScores.length; ++i) {
      highScoreRows.push(<div key={i}>{getScoreInfo(levelHighScores[i], i)}</div>);
    }
  } else {
    // Render a dummy high score board to avoid screen flash after scores are loaded from the server
    for (let i=0; i !== 10; ++i) {
      highScoreRows.push(<div className={styles.dummyHighScores} key={i}>
                                                                  {getScoreInfo(null, i)}</div>);
    }
  }

  return (
          <>
            <div className={`${styles.personalHighScore} ${theme}`}>
            {!showPractice || <div>{`${currentLevelBestPracticeTime ?
                    "Your Best Practice Time For Level "+selectedLevel+" is "+currentLevelBestPracticeTime
                  : "Your Best Practice Time Will Display Here."}`}</div>}
            {showPractice || <div>{`${currentLevelPersonalHighScore ?
                    "Your High Score For Level "+selectedLevel+" is "+currentLevelPersonalHighScore
                  : "Your Personal High Score Will Display Here."}`}</div>}
            </div>
            <div className={styles.LevelHighScores}>
              <div className={`${styles.scoreBoard} ${theme}`}>
                <div className={`${styles.title} ${theme}`}>
                  <h3>Level {selectedLevel} High Scores</h3>
                </div>
                <div className={`${styles.theScores} ${theme}`}>
                  {highScoreRows}
                </div>
              </div>
            </div>
          </>
         );
});

export default HighScores;
