import "./CommandLine.css";
import { useContext, memo } from "react";
import { ThemeContext } from "./App.js";

const CommandLine = memo(({gameIsInProgress, leftMessage, rightMessage,
                           alertMessage, giveSuggestion}) => {
  const isRightMessageFindOrTill = () => {
    if (rightMessage) {
      const firstCharUpper = rightMessage.substring(0 ,1).toUpperCase();
      return firstCharUpper === "F" || firstCharUpper === "T";
    }
    return false;
  }
  return (
          <div className={`CommandLine text-monospace ${!gameIsInProgress
                                     || (leftMessage === undefined && rightMessage === undefined) ?
                                                  "command-line-inactive" : "command-line-active"}
                           ${useContext(ThemeContext)}`}>
            {gameIsInProgress &&
              <>
                <span className="left-message">{leftMessage}</span>
                { !giveSuggestion || !leftMessage ||
                     <span className="command-line-suggestion command-line-suggestion-left"
                           style={{fontSize: leftMessage.length > 12 ? "80%" : "100%"}}>
                        <svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="1em" width="1em" style={{paddingRight: "2px"}}>
                          <path fill="currentColor" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"></path>
                        </svg>
                        Enter your search string or press "Esc"
                     </span>
                }
                <span className="right-message">{rightMessage}</span>
                { !giveSuggestion || !rightMessage ||
                     <span className="command-line-suggestion command-line-suggestion-right">
                     { isRightMessageFindOrTill() ? `Enter target character or press "Esc"`
                                                  : `Enter a command or press "Esc"`}
                        <svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="1em" width="1em" style={{paddingLeft: "2px"}}>
                          <path fill="currentColor" d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"></path>
                        </svg>
                     </span>
                }
                <span className="alert-message">{alertMessage}</span>
              </>
            }
          </div>
         );
});

export default CommandLine;
